.campo::placeholder {
    font-size: 14pt;
}
.p-button.p-button-btxpp, .p-buttonset.p-button-btxpp > .p-button, .p-splitbutton.p-button-btxpp > .p-button, .p-fileupload-choose.p-button-btxpp {
    color: #ffffff;
    background: #58adb1;
    border: 1px solid #58adb1;
}
.p-button.p-button-btxpp:enabled:hover, .p-button.p-button-btxpp:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-btxpp > .p-button:enabled:hover, .p-buttonset.p-button-btxpp > .p-button:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-btxpp > .p-button:enabled:hover, .p-splitbutton.p-button-btxpp > .p-button:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-btxpp:enabled:hover, .p-fileupload-choose.p-button-btxpp:not(button):not(a):not(.p-disabled):hover {
    background: #225b5c;
    color: #ffffff;
    border-color: #225b5c;
}
.p-button.p-button-btxpp:enabled:focus, .p-button.p-button-btxpp:not(button):not(a):not(.p-disabled):focus, .p-buttonset.p-button-btxpp > .p-button:enabled:focus, .p-buttonset.p-button-btxpp > .p-button:not(button):not(a):not(.p-disabled):focus, .p-splitbutton.p-button-btxpp > .p-button:enabled:focus, .p-splitbutton.p-button-btxpp > .p-button:not(button):not(a):not(.p-disabled):focus, .p-fileupload-choose.p-button-btxpp:enabled:focus, .p-fileupload-choose.p-button-btxpp:not(button):not(a):not(.p-disabled):focus {
    box-shadow: 0 0 0 0.2rem #E2E8F0;
}
.p-button.p-button-btxpp:enabled:active, .p-button.p-button-btxpp:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-btxpp > .p-button:enabled:active, .p-buttonset.p-button-btxpp > .p-button:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-btxpp > .p-button:enabled:active, .p-splitbutton.p-button-btxpp > .p-button:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-btxpp:enabled:active, .p-fileupload-choose.p-button-btxpp:not(button):not(a):not(.p-disabled):active {
    background: #7a95ad;
    color: #ffffff;
    border-color: #7a95ad;
}
.p-button.p-button-btxpp.p-button-outlined, .p-buttonset.p-button-btxpp > .p-button.p-button-outlined, .p-splitbutton.p-button-btxpp > .p-button.p-button-outlined, .p-fileupload-choose.p-button-btxpp.p-button-outlined {
    background-color: transparent;
    color: #58adb1;
    border: 1px solid;
}
.p-button.p-button-btxpp.p-button-outlined:enabled:hover, .p-button.p-button-btxpp.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-btxpp > .p-button.p-button-outlined:enabled:hover, .p-buttonset.p-button-btxpp > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-btxpp > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-btxpp > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-btxpp.p-button-outlined:enabled:hover, .p-fileupload-choose.p-button-btxpp.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
    background: rgba(100, 116, 139, 0.04);
    color: #58adb1;
    border: 1px solid;
}
.p-button.p-button-btxpp.p-button-outlined:enabled:active, .p-button.p-button-btxpp.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-btxpp > .p-button.p-button-outlined:enabled:active, .p-buttonset.p-button-btxpp > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-btxpp > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-btxpp > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-btxpp.p-button-outlined:enabled:active, .p-fileupload-choose.p-button-btxpp.p-button-outlined:not(button):not(a):not(.p-disabled):active {
    background: rgba(100, 116, 139, 0.16);
    color: #58adb1;
    border: 1px solid;
}
.p-button.p-button-btxpp.p-button-text, .p-buttonset.p-button-btxpp > .p-button.p-button-text, .p-splitbutton.p-button-btxpp > .p-button.p-button-text, .p-fileupload-choose.p-button-btxpp.p-button-text {
    background-color: transparent;
    color: #58adb1;
    border-color: transparent;
}
.p-button.p-button-btxpp.p-button-text:enabled:hover, .p-button.p-button-btxpp.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-btxpp > .p-button.p-button-text:enabled:hover, .p-buttonset.p-button-btxpp > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-btxpp > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-btxpp > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-btxpp.p-button-text:enabled:hover, .p-fileupload-choose.p-button-btxpp.p-button-text:not(button):not(a):not(.p-disabled):hover {
    background: rgba(100, 116, 139, 0.04);
    border-color: transparent;
    color: #58adb1;
}
.p-button.p-button-btxpp.p-button-text:enabled:active, .p-button.p-button-btxpp.p-button-text:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-btxpp > .p-button.p-button-text:enabled:active, .p-buttonset.p-button-btxpp > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-btxpp > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-btxpp > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-btxpp.p-button-text:enabled:active, .p-fileupload-choose.p-button-btxpp.p-button-text:not(button):not(a):not(.p-disabled):active {
    background: rgba(100, 116, 139, 0.16);
    border-color: transparent;
    color: #58adb1;
}
.p-splitbutton.p-button-btxpp.p-button-outlined > .p-button {
    background-color: transparent;
    color: #58adb1;
    border: 1px solid;
}
.p-splitbutton.p-button-btxpp.p-button-outlined > .p-button:enabled:hover, .p-splitbutton.p-button-btxpp.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(100, 116, 139, 0.04);
    color: #58adb1;
}
.p-splitbutton.p-button-btxpp.p-button-outlined > .p-button:enabled:active, .p-splitbutton.p-button-btxpp.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(100, 116, 139, 0.16);
    color: #58adb1;
}
.p-splitbutton.p-button-btxpp.p-button-text > .p-button {
    background-color: transparent;
    color: #58adb1;
    border-color: transparent;
}
.p-splitbutton.p-button-btxpp.p-button-text > .p-button:enabled:hover, .p-splitbutton.p-button-btxpp.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(100, 116, 139, 0.04);
    border-color: transparent;
    color: #58adb1;
}
.p-splitbutton.p-button-btxpp.p-button-text > .p-button:enabled:active, .p-splitbutton.p-button-btxpp.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(100, 116, 139, 0.16);
    border-color: transparent;
    color: #58adb1;
}
